<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title v-if="operation === 'create'">{{createTitle}}</v-toolbar-title>
      <v-toolbar-title v-if="operation === 'update'">{{ updateTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text class="overflow-y-auto">
      <v-row align-content="end">
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="operation === 'create' || this.operation === 'reupdate'" text
               @click="createIntervenant">
          {{ createButton }}
        </v-btn>
        <v-btn color="primary" v-if="operation === 'update' || this.operation === 'reupdate'" text
               @click="updateIntervenant">
          Mettre à jour
        </v-btn>
      </v-row>
      <v-form ref="userForm">
        <v-text-field label="Nom d'utilisateur*" autocomplete="username" v-model="intervenant.username"
                      :rules="[value => value.length >= 3 || 'Doit faire au moins 3 caractères.']"></v-text-field>
        <v-text-field label="Prénom*" :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide.']" v-model="intervenant.first_name"></v-text-field>
        <v-text-field label="Nom*" :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide.']" v-model="intervenant.last_name"></v-text-field>
        <v-text-field label="E-mail*" type="email" :rules="[isValidEmail]" v-model="intervenant.email"></v-text-field>
        <v-checkbox v-model="changePassword" @change="resetPasswordField" v-if="operation === 'update'" label="Modifier le mot de passe"></v-checkbox>
        <v-text-field autocomplete="new-password" :disabled="!changePassword" :rules="passwordRules"
                      label="Mot de passe*" type="password"
                      v-model="intervenant.password"></v-text-field>
        <v-text-field :disabled="!changePassword"
                      :rules="passwordConfirmationRules"
                      label="Confirmation du mot de passe*" type="password"
                      v-model="passwordConfirmation"></v-text-field>
        <v-select label="Groupe*" @change="changeForm()" :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide.']" :items="groups" item-value="id"
                  item-text="name" v-model="intervenant.group"></v-select>
        <v-text-field type="number" label="Téléphone fixe" v-model="intervenant.profile.tel_fixe"></v-text-field>
        <v-text-field type="number" label="Téléphone mobile" v-model="intervenant.profile.tel_mobile"></v-text-field>
        <v-select label="Métiers" multiple :items="metiers" item-text="name" item-value="id"
                  v-model="intervenant.profile.metiers"></v-select>
        <h4>Sites assignés</h4>
        <v-treeview v-if="isResponsableOrAdmin"
                    v-model="intervenant.profile.sites"
                    selectable
                    item-text="name"
                    item-children="sites"
                    item-key="id"
                    :items="sites_tree"
                    ></v-treeview>
        <v-row v-if="showPrestataire">
          <v-col cols="12">Prestataire</v-col>
        </v-row>
        <v-text-field v-if="showPrestataire" label="Raison sociale"
                      v-model="intervenant.profile.prestataire.raison_sociale"></v-text-field>
        <v-textarea v-if="showPrestataire" label="Adresse"
                    v-model="intervenant.profile.prestataire.adresse"></v-textarea>
        <v-text-field v-if="showPrestataire" label="Code postal"
                      v-model="intervenant.profile.prestataire.code_postale"></v-text-field>
        <v-text-field v-if="showPrestataire" label="SIRET"
                      v-model="intervenant.profile.prestataire.siret"></v-text-field>
        <v-checkbox v-if="showPrestataire" label="Envoyer directement les tickets à ce prestataire"
                    v-model="intervenant.profile.prestataire.direct_ticket"></v-checkbox>
      </v-form>
      <v-btn color="primary" v-if="operation === 'create' || this.operation === 'reupdate'" text
             @click="createIntervenant">
        {{createButton}}
      </v-btn>
      <v-btn color="primary" v-if="operation === 'update' || this.operation === 'reupdate'" text
             @click="updateIntervenant">
        Mettre à jour
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  name: "NewIntervenantSidebar",
  mixins: [DateUtilMixin, AuthMixin],
  props: {
    operation: String,
    intervenantToUpdate: Object,
    createTitle: {
      type: String,
      default: "Nouvel intervenant",
    },
    updateTitle: {
      type: String,
      default: "Mettre à jour l'intervenant",
    },
    createButton: {
      type: String,
      default: "Créer l'intervenant",
    },
    groups: {
      type: Array,
      default: () => [],
    }
  },
  async mounted() {
    if (this.$store.getters["assets/allAssets"].length === 0) {
      await this.$store.dispatch("assets/fetchAssets");
    }
    if (this.$store.getters["contracts/allContracts"].length === 0 && this.hasPermission("view_contract")) {
      await this.$store.dispatch("contracts/fetchContracts");
    }
    if (this.$store.getters["prestataires/allPrestataires"].length === 0) {
      await this.$store.dispatch("prestataires/fetchPrestataires");
    }
    if (this.$store.getters["categories/allCategories"].length === 0) {
      await this.$store.dispatch("categories/fetchCategories");
    }
    if (this.$store.getters["sites/allSites"].length === 0 && this.isResponsableOrAdmin) {
      await this.$store.dispatch("sites/fetchSites");
    }
    if (this.$store.getters["groups/groups"].length === 0) {
      await this.$store.dispatch("groups/fetchGroups");
    }
  },
  data: function () {
    let emptyIntervenant = {
      "first_name": "",
      "last_name": "",
      "username": "",
      "email": "",
      "group": null,
      "groups": [],
      "password": "",
      "profile": {
        "tel_fixe": "",
        "tel_mobile": "",
        "metiers": [],
        "sites": [],
        "prestataire": {
          "raison_sociale": "",
          "adresse": "",
          "code_postale": "",
          "siret": "",
          "direct_ticket": false,
        }
      }
    }
    let intervenantToUpdate = JSON.parse(JSON.stringify(this.intervenantToUpdate));


    if (this.operation === "update" && intervenantToUpdate !== null) {
      if (intervenantToUpdate.profile === null) {
        intervenantToUpdate.profile = {
          "tel_fixe": "",
          "tel_mobile": "",
          "metiers": [],
          "sites": [],
          "prestataire": {
            "raison_sociale": "",
            "adresse": "",
            "code_postale": "",
            "siret": "",
            "direct_ticket": false,
          }
        }
      } else if(intervenantToUpdate.profile.prestataire === null && intervenantToUpdate.group === "Prestataire") {
        intervenantToUpdate.profile.prestataire = {
            "raison_sociale": "",
            "adresse": "",
            "code_postale": "",
            "siret": "",
            "direct_ticket": false,
          }
      }
      intervenantToUpdate.profile.metiers = this.changeMetiersFormat(intervenantToUpdate);
      intervenantToUpdate.group = this.changeGroupFormat(intervenantToUpdate);
      intervenantToUpdate.profile.site = this.changeSiteFormat(intervenantToUpdate);
      intervenantToUpdate.profile.sites = this.changeSitesFormat(intervenantToUpdate);
    }
    return {
      intervenant: this.operation === "update" ? intervenantToUpdate : emptyIntervenant,
      types: ["Prestataire", "Collaborateur"],
      passwordConfirmation: "",
      changePassword: this.operation === "create",
    }
  },
  computed: {
    assets() {
      return this.$store.getters["assets/allAssets"];
    },
    prestataires() {
      return this.$store.getters["prestataires/allPrestataires"];
    },
    metiers() {
      return this.$store.getters["categories/metiers"];
    },
    sites() {
      return [{"id": null, name: "Tous les sites"}, ...this.$store.getters["sites/fullLocationSites"]]
    },
    groups_intervenant() {
      return this.$store.getters["groups/groups"];
    },
    showPrestataire() {
      return this.groups_intervenant.length > 0 && (this.intervenant.group === this.groups_intervenant.filter(item => item.name === "Prestataire")[0].id || this.intervenant?.profile?.prestataire !== null);
    },
    areSamePassword() {
      return this.intervenant.password === this.passwordConfirmation;
    },
    samePassword() {
      return () => (this.intervenant.password === this.passwordConfirmation) || "Les deux mots de passe doivent être identiques";
    },
    passwordRules() {
      return this.changePassword ? [this.eightCharactersLongMin] : [];
    },
    passwordConfirmationRules() {
      return this.changePassword ? [this.samePassword, this.eightCharactersLongMin] : [];
    },
    sites_tree() {
      return this.$store.getters["sites/sitesTree"];
    },
    selected_profile() {
      return this.$store.getters["profile/getSelectedProfile"];
    }
  },
  methods: {
    async createIntervenant() {
      if (this.$refs.userForm.validate()) {
        await this.$store.dispatch("profile/postProfile", this.intervenant);
        await this.updatePrestataireListWhenPrestataireUpdated();
        await this.$store.dispatch("drawer/closeDialog");
        this.updateLoggedUserIfProfileModified();
        this.resetFields();
      }
    },
    updateIntervenant() {
      if(this.$refs.userForm.validate()) {
        this.cleanFields();
        this.$store.dispatch("profile/patchProfile", this.intervenant);
        this.updatePrestataireListWhenPrestataireUpdated();
        this.$store.dispatch("drawer/closeDialog");
        this.resetFields();

      }
    },
    async updatePrestataireListWhenPrestataireUpdated() {
      let group = this.groups.find(group => group.id === this.intervenant.group);
      if(group !== undefined && group.name === "Prestataire") {
        await this.$store.dispatch("prestataires/fetchPrestataires");
      }
    },
    updateLoggedUserIfProfileModified() {
      if(this.selected_profile.id === this.logged_user.id) {
          this.$store.commit("users/setLoggedUser", {...this.logged_user, ...this.selected_profile})
        }
    },
    resetFields() {
      this.intervenant = {
        "first_name": "",
        "last_name": "",
        "username": "",
        "email": "",
        "group": null,
        "groups": [],
        "password": "",
        "profile": {
          "tel_fixe": "",
          "tel_mobile": "",
          "metiers": [],
          "site": null,
          "prestataire": {
            "raison_sociale": "",
            "adresse": "",
            "code_postale": "",
            "siret": "",
            "direct_ticket": false,
          }
        }
      }
    },
    changeForm() {
      if (this.intervenant.group === this.groups_intervenant.filter(item => item.name === "Prestataire")[0].id) {
        this.intervenant.profile.prestataire = {
          "raison_sociale": "",
          "adresse": "",
          "code_postale": "",
          "siret": "",
          "direct_ticket": false,
        };
      } else {
        this.intervenant.profile.prestataire = null;
      }
    },
    cleanFields() {
      if (typeof this.intervenant.group !== "number") {
        delete this.intervenant.group;
      }
      delete this.intervenant.username;
      if (this.intervenant.profile.prestataire === null) {
        delete this.intervenant.profile.prestataire;
      }
      if(!this.isResponsableOrAdmin) {
        delete this.intervenant?.profile?.site;
      }
      if(!this.notNullOrEmpty(this.intervenant?.password) || !this.notNullOrEmpty(this.passwordConfirmation)) {
        delete this.intervenant?.password;
      }
    },
    changeMetiersFormat(intervenantToUpdate) {
      if (intervenantToUpdate.profile?.metiers !== undefined && intervenantToUpdate.profile.metiers.length > 0) {
        let metiers = [];
        for (let metier of intervenantToUpdate.profile.metiers) {
          metiers.push(metier);
        }
        let correctType = typeof metiers[0] === "number";
        return !correctType ? metiers.map(item => item.id) : metiers;
      }
    },
    changeSiteFormat(intervenant) {
      let site = intervenant?.profile?.site;
      if (typeof site === "object") {
        return site?.id;
      } else {
        return site;
      }
    },
    changeSitesFormat(intervenant) {
      let sites = intervenant?.profile?.sites;
      if(Array.isArray(sites)) {
        return sites.map(site => site?.id);
      } else {
        return [];
      }
    },
    changeGroupFormat(intervenantToUpdate) {
      let groups = this.$store.getters["groups/groups"];
      if (intervenantToUpdate.group !== undefined && typeof intervenantToUpdate.group === "string" && groups !== undefined && groups.length > 0) {
        let group = groups.find(item => item.name === intervenantToUpdate.group);
        if(group) return group.id;
        else return null;
      } else {
        return intervenantToUpdate.group;
      }
    },
    resetPasswordField() {
      this.intervenant.password = null;
      this.passwordConfirmation = null;
    },
    eightCharactersLongMin(value) {
      return (value !== null && value?.length > 7) || "Le mot de passe n'est pas assez long.";
    },
    notNullOrEmpty(value) {
      return value !== null && value !== "" && value !== undefined;
    },
    isValidEmail(value) {
      return value !== null && value !== '' && /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || 'Doit être une adresse email valide';
    }
    
  }
}
</script>

<style scoped>

</style>